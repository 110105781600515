import { Accessor, JSX, Show } from "solid-js";
import { useLocation, useNavigate, useSearchParams, A } from "@solidjs/router";
import { useAppState } from "~/AppContext";
import { Avatar, Plus } from "./icons";
import { Account } from "~/types/models";
import CasdoorLogin from "./CasdoorLogin";

export default function Nav({
  currentUser,
}: {
  currentUser: Accessor<Account | undefined>;
}) {
  // deferStream: true cause contents order reversed on refresh
  const { t } = useAppState();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const goToSearch: JSX.EventHandler<HTMLInputElement, KeyboardEvent> = (e) => {
    if (e.key === "Enter") {
      navigate(`/search?q=${e.currentTarget.value}`);
      // location.pathname === "/"
      //   ? navigate(`/search?q=${e.currentTarget.value}`)
      //   : setSearchParams({ q: e.currentTarget.value });
    }
  };

  return (
    <nav class="border-gray-200 hidden md:inline">
      <div class="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
        <A href="/" class="flex items-center justify-center">
          {/* <img src={logo} class="h-8 mr-3" alt="Flowbite Logo" /> */}
          <span class="self-center text-4xl font-semibold whitespace-nowrap dark:text-white">
            {t("app.title")}
          </span>
        </A>

        <div
          class="items-center justify-center mx-2 md:ml-8 md:flex md:w-auto"
          id="navbar-cta"
        >
          <div class="relative w-full max-w-lg hidden md:block">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                class="w-5 h-5 text-gray-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="sr-only">Search icon</span>
            </div>
            <input
              type="text"
              id="search-navbar"
              class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder={`${t("search")}...`}
              onKeyDown={goToSearch}
            />
          </div>
        </div>
        <span class="grow" />
        <Show
          when={currentUser()}
          fallback={
            <div class="flex items-center">
              <CasdoorLogin title={t("signin")} />
            </div>
          }
        >
          {(user) => (
            <div class="flex items-center gap-4">
              <A
                href="/submit"
                class="font-medium 
                text-gray-600 hover:text-black dark:hover:text-white rounded-sm  text-center"
              >
                <Plus size={10} />
              </A>
              <A
                href="/dash"
                class="p-0.5 rounded-full hover:bg-gray-200 dark:hover:bg-gray-800"
              >
                <Show
                  when={user().avatar}
                  fallback={<Avatar class="rounded-full w-10 h-10" />}
                >
                  {(avatar) => (
                    <img
                      src={avatar()}
                      alt="user avatar"
                      class="rounded-full w-10 h-10"
                    />
                  )}
                </Show>
              </A>
            </div>
          )}
        </Show>
      </div>
    </nav>
  );
}
